import {
    FETCH_TEMPLATE_AUTO_TASK_BASED_ON_AUTO_TASK_ID_REQUEST,
    FETCH_TEMPLATE_AUTO_TASK_BASED_ON_AUTO_TASK_ID_SUCCESS,
    FETCH_TEMPLATE_AUTO_TASK_BASED_ON_AUTO_TASK_ID_FAILURE,
} from './getTemplateAutoTaskBasedOnAutoTaskIdTypes'

const initialState = {
  loading: false,
  particularTemplateAutoTaskInfo: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_TEMPLATE_AUTO_TASK_BASED_ON_AUTO_TASK_ID_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_TEMPLATE_AUTO_TASK_BASED_ON_AUTO_TASK_ID_SUCCESS : 
    return {
      ...state,
      particularTemplateAutoTaskInfo: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_TEMPLATE_AUTO_TASK_BASED_ON_AUTO_TASK_ID_FAILURE : return {
      ...state,
      loading: false,
      particularTemplateAutoTaskInfo: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
