import {
    FETCH_DELETE_PROJECT_TEMPLATE_AUTO_TASK_LANE_REQUEST,
    FETCH_DELETE_PROJECT_TEMPLATE_AUTO_TASK_LANE_SUCCESS,
    FETCH_DELETE_PROJECT_TEMPLATE_AUTO_TASK_LANE_FALUIRE,
} from './deleteProjectTemplateAutoTaskLaneTypes'

const initialState = {
  loading: false,
  deleteProjectTemplateAutoTaskLane: null,
  error: '',
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_DELETE_PROJECT_TEMPLATE_AUTO_TASK_LANE_REQUEST : return {
      ...state,
      loading: true,

    }

    case FETCH_DELETE_PROJECT_TEMPLATE_AUTO_TASK_LANE_SUCCESS : return {
      ...state,
      deleteProjectTemplateAutoTaskLane: action.payload,
      error: '',
      loading: false,
    }

    case FETCH_DELETE_PROJECT_TEMPLATE_AUTO_TASK_LANE_FALUIRE : return {
      ...state,
      loading: false,
      deleteProjectTemplateAutoTaskLane: null,
      error: action.payload,
    }
    default : return state
  }
}

export default reducer
