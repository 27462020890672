import {
    FETCH_LIST_ALL_TEMPLATE_AUTO_TASK_REQUEST,
    FETCH_LIST_ALL_TEMPLATE_AUTO_TASK_SUCCESS,
    FETCH_LIST_ALL_TEMPLATE_AUTO_TASK_FALUIRE,
    FETCH_LIST_ALL_TEMPLATE_AUTO_TASK_MINUUS_ONE_FALUIRE,
    FETCH_LIST_ALL_TEMPLATE_AUTO_TASK_COUNT_REQUEST,
    SEARCH_ALL_AUTO_TASK
  
} from './listAllTemplateAutoTaskTypes';

const myFilter = (data, searchValue) => {
  let taskListing=[]  
  data &&
   // eslint-disable-next-line
    data.map((data1) => {
      let task = [];
      let obj = {
        name: data1.name,
        order_number: data1.order_number,
        project_task_group_id:data1.project_task_group_id
      };
      data1 &&
       // eslint-disable-next-line
        data1.task.map((data2) => {
          if (data2?.name?.toLowerCase()?.includes(searchValue)) {
            task.push(data2);
          }
        });
      obj['task'] = task;
      taskListing.push(obj);
      
    });  
   

  return taskListing ;
};

const initialState = {
  loading: false,
  allTemplateAutoTaskList: null, 
//   aTList:null,
//   completedTemplateTaskList:[],
//   countForTaskHeader:null,
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIST_ALL_TEMPLATE_AUTO_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };
   
    //   case FETCH_LIST_ALL_TEMPLATE_AUTO_TASK_COUNT_REQUEST:
    //     return {
    //       ...state,
    //       countForTaskHeader: action.payload,
    //     };

        // case FETCH_LIST_ALL_TEMPLATE_AUTO_TASK_MINUUS_ONE_FALUIRE:
        //   return {
        //     ...state,
        //     completedTemplateTaskList: state.completedTemplateTaskList.concat(action.payload) 
        //   };
        

    case FETCH_LIST_ALL_TEMPLATE_AUTO_TASK_SUCCESS:
      return {
        ...state,
        allTemplateAutoTaskList: action.payload,
        // aTList:action.payload,
        error: '',
        loading: false,
      };

      
    // case SEARCH_ALL_AUTO_TASK:
    //   return {
    //     ...state,
    //     allTemplateAutoTaskList:myFilter(state?.aTList,action?.payload?.toLowerCase())       
    //   };

    case FETCH_LIST_ALL_TEMPLATE_AUTO_TASK_FALUIRE:
      return {
        ...state,
        loading: false,
        allTemplateAutoTaskList: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
