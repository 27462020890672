import EnLocale from './locales/en.json'
import CsLocale from './locales/cs.json'
import RuLocale from './locales/ru.json'
import PtLocale from './locales/pt.json'
import EsLocale from './locales/es.json'
import IdLocale from './locales/id.json'
import FrLocale from './locales/fr.json'
import DaLocale from './locales/da.json'
import SvLocale from './locales/sv.json'
import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next'

i18n.use(LanguageDetector).init({
  debug: false,
  fallbackLng: 'da', 
  lng: 'da',
  keySeparator: ".",
  interpolation: {escapeValue: false},

  resources: {
    da: {translations: DaLocale},
    en: {translations: EnLocale},
    cs: {translations: CsLocale},
    id: {translations: IdLocale},
    fr: {translations: FrLocale},
    pt: {translations: PtLocale},
    es: {translations: EsLocale},
    ru: {translations: RuLocale},
    sv: {translations: SvLocale}

  },
  ns: ['translations'],
  defaultNS: 'translations',
  react: {
    useSuspense: false,   
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default',
  },
  returnObjects: true,
})

export default i18n
